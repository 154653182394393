import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: '/tagsone',
        name: 'TagsOne',
        component: () => import('../views/TagsOne.vue'),
      },
      {
        path: '/tagstwo',
        name: 'TagsTwo',
        component: () => import('../views/TagsTwo.vue'),
      },
      {
        path: '/tagsthree',
        name: 'TagsThree',
        component: () => import('../views/TagsThree.vue'),
      },
      {
        path: '/tagsfour',
        name: 'TagsFour',
        component: () => import('../views/TagsFour.vue'),
      },
      {
        path: '/money',
        name: 'Money',
        component: () => import('../views/money.vue'),
      },

    ]
  },
  {
    path: '/rhome',
    name: 'rHome',
    component: () => import('../views/ReimbursementPage/home.vue'),
    children: [
      {
        path: '/addlyfor',
        name: 'addlyFor',
        component: () => import('../views/ReimbursementPage/addlyfor.vue'),
      },
      {
        path: '/examine',
        name: 'exaMine',
        component: () => import('../views/ReimbursementPage/examine.vue'),
      },
    ]
  },
  {
    path: '/teamhome',
    name: 'teamHome',
    component: () => import('../views/teambaoxiaopage/home.vue'),
    children: [
      {
        path: '/teamaddlyfor',
        name: 'teamaddlyfor',
        component: () => import('../views/teambaoxiaopage/teamaddlyfor.vue'),
      },
      {
        path: '/teamexamine',
        name: 'teamexamine',
        component: () => import('../views/teambaoxiaopage/teamexamine.vue'),
      },
    ]
  },
  {
    path: '/phome',
    name: 'pHome',
    component: () => import('../views/payment/phome.vue'),
    children: [
      {
        path: '/fkaddlyfor',
        name: 'fkaddlyFor',
        component: () => import('../views/payment/fkaddlyfor.vue'),
      },
      {
        path: '/fkexamine',
        name: 'fkexaMine',
        component: () => import('../views/payment/fkexamine.vue'),
      },
    ]
  },
  {
    path: '/byjhome',
    name: 'byjHome',
    component: () => import('../views/Imprest/byjhome.vue'),
    children: [
      {
        path: '/byjaddlyfor',
        name: 'byjaddlyFor',
        component: () => import('../views/Imprest/byjaddlyfor.vue'),
      },
      {
        path: '/byjexamine',
        name: 'byjexaMine',
        component: () => import('../views/Imprest/byjexamine.vue'),
      },
    ]
  },
  {
    path: '/commoditySq',
    name: 'commoditySq',
    component: () => import('../views/Findroducts/commoditySq.vue'),
  },
  {
    path: '/moneyDetailsZp',
    name: 'moneyDetailsZp',
    component: () => import('../views/Findroducts/moneyDetails.vue'),
  },
  {
    path: '/applyforZp',
    name: 'applyforZp',
    component: () => import('../views/Findroducts/applyfor.vue'),
  },
  {
    path: '/slectXp',
    name: 'slectXp',
    component: () => import('../views/Findroducts/slectXp.vue'),
  },
  {
    path: '/examineSp',
    name: 'examineSp',
    component: () => import('../views/Findroducts/examine.vue'),
  },
  {
    path: '/newProductPush',
    name: 'newProductPush',
    component: () => import('../views/Findroducts/newProductPush.vue'),
  },
  {
    path: '/newProductPushDetails',
    name: 'newProductPushDetails',
    component: () => import('../views/Findroducts/newProductPushDetails.vue'),
  },
  {
    path: '/yfkhome',
    name: 'yfkHome',
    component: () => import('../views/AdvanceCharge/yfkhome.vue'),
    children: [
      {
        path: '/yfkaddlyfor',
        name: 'yfkaddlyFor',
        component: () => import('../views/AdvanceCharge/yfkaddlyfor.vue'),
      },
      {
        path: '/yfkexamine',
        name: 'yfkexaMine',
        component: () => import('../views/AdvanceCharge/yfkexamine.vue'),
      },
      {
        path: '/hxaddlyfor',
        name: 'hxaddlyFor',
        component: () => import('../views/AdvanceCharge/hxaddlyfor.vue'),
      },
      {
        path: '/hxexamine',
        name: 'hxexaMine',
        component: () => import('../views/AdvanceCharge/hxexamine.vue'),
      },
    ]
  },
  {
    path: '/moneydetails',
    name: 'moneyDetails',
    component: () => import('../views/ReimbursementPage/moneyDetails.vue'),
  },
  {
    path: '/teammoneydetails',
    name: 'teammoneyDetails',
    component: () => import('../views/teambaoxiaopage/moneyDetails.vue'),
  },
  {
    path: '/xzmoneydetails',
    name: 'xzmoneyDetails',
    component: () => import('../views/office/moneyDetailsXz.vue'),
  },

  {
    path: '/fkmoneydetails',
    name: 'fkmoneyDetails',
    component: () => import('../views/payment/fkmoneyDetails.vue'),
  },
  {
    path: '/byjmoneydetails',
    name: 'byjmoneyDetails',
    component: () => import('../views/Imprest/byjmoneyDetails.vue'),
  },
  {
    path: '/yfkmoneydetails',
    name: 'yfkmoneyDetails',
    component: () => import('../views/AdvanceCharge/yfkmoneyDetails.vue'),
  },
  {
    path: '/hxmoneydetails',
    name: 'hxmoneyDetails',
    component: () => import('../views/AdvanceCharge/hxmoneyDetails.vue'),
  },
  {
    path: '/messagelist',
    name: 'messageList',
    component: () => import('../views/messageList.vue'),
  },
  // 更改密码
  {
    path: '/changepass',
    name: 'changepass',
    component: () => import('../views/changePass/index.vue'),
  },
  // 抄送消息
  {
    path: '/csmsg',
    name: 'csmsg',
    component: () => import('../views/csList/csList.vue'),
  },
  // 上传发票
  {
    path: '/uploadInvoiceFile',
    name: 'uploadInvoiceFile',
    component: () => import('../views/uploadInvoiceFile/uploadInvoiceFile.vue'),
  },
  // 上传备用金发票
  {
    path: '/returnimprest',
    name: 'returnimprest',
    component: () => import('../views/returnimprest/returnimprest.vue'),
  },
  // 结算预付款
  {
    path: '/advancechargeFile',
    name: 'advancechargeFile',
    component: () => import('../views/advancechargeFile/advancechargeFile.vue'),
  },
  {
    path: '/myExamine',
    name: 'myExamine',
    component: () => import('../views/myExamine/myExamine.vue'),
  },
  // 财务报销统计
  {
    path: '/financialstatistics',
    name: 'financialStatistics',
    component: () => import('../views/financialStatistics/financialStatistics.vue'),
  },
  // 申请单home
  {
    path: '/procureApplyfor',
    name: 'procureApplyfor',
    component: () => import('../views/procure/home.vue'),
  },
  // 采购单详情
  {
    path: '/procurddetails',
    name: 'procurDdetails',
    component: () => import('../views/procure/details.vue'),
  },
  // 采购单详情(王)
  {
    path: '/wangdetails',
    name: 'wangdetails',
    component: () => import('../views/procure/wangdetails.vue'),
  },
  // 采购审批
  {
    path: '/buyreview',
    name: 'buyReview',
    component: () => import('../views/procure/buyreview.vue'),
  },
  // 采购单信息(王)
  {
    path: '/cgbuyreview',
    name: 'cgbuyreview',
    component: () => import('../views/procure/cgbuyreview.vue'),
  },
  // 申请消息
  {
    path: '/sqmessagelist',
    name: 'sqmessageList',
    component: () => import('../views/sqmessageList.vue'),
  },
  // 采购消息
  {
    path: '/cgmessagelist',
    name: 'cgmessageList',
    component: () => import('../views/cgmessageList.vue'),
  },
  // 运费消息
  {
    path: '/yunfeimessagelist',
    name: 'yunfeimessageList',
    component: () => import('../views/yunfeimessageList.vue'),
  },
  // 报销费用详情
  {
    path: '/rmbdetails',
    name: 'rmbdetails',
    component: () => import('../views/rmbdetails.vue'),
  },
  // 项目负责人审批详情
  {
    path: '/sqddetails',
    name: 'sqdDetails',
    component: () => import('../views/procure/sqdDetails.vue'),
  },
  {
    path: '/goodsreq',
    name: 'goodsreq',
    component: () => import('../views/goodsreq.vue'),
  },
  {
    path: '/goodsreqlist',
    name: 'goodsreqList',
    component: () => import('../views/goodsreqList.vue'),
  },
  {
    path: '/goodscgdinfo',
    name: 'goodscgdInfo',
    component: () => import('../views/goodscgdInfo.vue'),
  },
  {
    path: '/goodsyunfeiinfo',
    name: 'goodsyunfeiInfo',
    component: () => import('../views/goodsyunfeiInfo.vue'),
  },
  // 聊天记录
  // {
  //   path: '/chatteamlist',
  //   name: 'chatteamList',
  //   component: () => import('../views/wechatpage/chatteamList.vue'),
  // },
  {
    path: '/office',
    name: 'office',
    component: () => import('../views/office/office.vue')
  },
  {
    path: '/officedetails',
    name: 'officedetails',
    component: () => import('../views/office/officedetails.vue')
  },
  {
    path: '/xzmessagelist',
    name: 'xzmessageList',
    component: () => import('../views/office/xzmessagelist.vue')
  },
  {
    path: '/officehandle',
    name: 'officehandle',
    component: () => import('../views/office/officehandle.vue')
  },
  {
    path: '/manufacturer',
    name: 'manufacturer',
    component: () => import('../views/manufacturer/manufacturer.vue'),
  },
  {
    path: '/changjiadetails',
    name: 'changjiadetails',
    component: () => import('../views/manufacturer/changjiadetails.vue'),
  },
  {
    path: '/changjiaList',
    name: 'changjiaList',
    component: () => import('../views/manufacturer/changjiaList.vue'),
  },
  {
    path: '/changjiainfo',
    name: 'changjiainfo',
    component: () => import('../views/manufacturer/changjiaInfo.vue'),
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('../views/phone/phone.vue'),
  },
  {
    path: '/phoneDetails',
    name: 'phoneDetails',
    component: () => import('../views/phone/phoneDetails.vue'),
  },
  {
    path: '/phonemessagelist',
    name: 'phonemessageList',
    component: () => import('../views/phone/phonemessagelist.vue'),
  },
  {
    path: '/callList',
    name: 'callList',
    component: () => import('../views/WorkOrder/callList.vue'),
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('../views/WorkOrder/orderList.vue'),
  },
  {
    path: '/orderApprove',
    name: 'orderApprove',
    component: () => import('../views/WorkOrder/orderApprove.vue'),
  },
  {
    path: '/addOrder',
    name: 'addOrder',
    component: () => import('../views/WorkOrder/addOrder.vue'),
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/WorkOrder/orderDetail.vue'),
  },
  {
    path: '/editOrder',
    name: 'editOrder',
    component: () => import('../views/WorkOrder/editOrder.vue'),
  },
  {
    path: '/orderApproveDetail',
    name: 'orderApproveDetail',
    component: () => import('../views/WorkOrder/orderApproveDetail.vue'),
  },
  {
    path: '/addOrderAndFlowList',
    name: 'addOrderAndFlowList',
    component: () => import('../views/zpOrder/addOrderAndFlowList.vue'),
  },
  {
    path: '/addZpOrder',
    name: 'addZpOrder',
    component: () => import('../views/zpOrder/addZpOrder.vue'),
  },
  {
    path: '/zpOrderDetail',
    name: 'zpOrderDetail',
    component: () => import('../views/zpOrder/zpOrderDetail.vue'),
  },
  {
    path: '/hr/seeOrder',
    name: 'hrOrderDetail',
    component: () => import('../views/zpOrder/components/orderSeeDetail.vue'),
  },
  {
    path: '/hr/editOrder',
    name: 'zpOrderEdit',
    component: () => import('../views/zpOrder/components/editOrder.vue'),
  },
  {
    path: '/officeBaoXiao',
    name: 'officeBaoXiao',
    component: () => import('../views/office/officeBaoXiao.vue'),
    children: [
      {
        path: '/addlyfor',
        name: 'addlyFor',
        component: () => import('../views/office/components/addlyfor.vue'),
      },
      {
        path: '/examine',
        name: 'exaMine',
        component: () => import('../views/office/components/examine.vue'),
      },
    ]
  },
  {
    path: '/newQuailty',
    component: () => import('../views/newQuailty/newQuailty.vue'),
    name: 'newQuailty',
  },
  {
    path: '/newQuailty/orderInfo',
    name: 'newQuailtyOrderInfo',
    component: () => import('../views/newQuailty/orderInfo.vue'),
  },
  {
    path: '/newQuailty/editOrder',
    name: 'newQuailtyEditOrder',
    component: () => import('../views/newQuailty/component/editOrder.vue'),
  },
  {
    path: '/examine',
    name: 'examine',
    component: () => import('../views/office/components/examine.vue'),
  },
  {
    path: '/yzsqList',
    name: 'yzsqList',
    component: () => import('../views/office/yzsqList.vue')
  },
  {
    path: '/yzsqDetails',
    name: 'yzsqDetails',
    component: () => import('../views/office/yzsqDetails.vue')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  let isLogin = localStorage.getItem("token"); // 是否登录
  if (to.path === "/") {
    next();
  } else {
    if (isLogin) {
      next();
    } else {
      router.replace("/");
    }
  }
});


export default router
